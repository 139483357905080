// const input = $('input[type="radio"]');
// const form = document.getElementById("form");
// const next = $(".form__next");

// next.on("click", function() {
//   //console.log($(this));
//   if ($(this).hasClass("disabled") == false) {
//     console.log(
//       $(this)
//         .parent()
//         .parent()
//         .removeClass("active")
//         .addClass("prev")
//     );
//     $(this)
//       .parent()
//       .parent()
//       .next()
//       .addClass("active");
//   }
// });

// input.on("change", function() {
//   $(this)
//     .parent()
//     .parent()
//     .parent()
//     .find(".form__next")
//     .removeClass("disabled");
// });

// // if (index === 0) {
// //   prev.getElementsByClassName.display = "none";
// // } else {
// //   prev.getElementsByClassName.display = "block";
// // }

// // next.addEventListener("click", e => {
// //   next.classList.contains("disabled") ? null : index++;
// // });


//============================================
// Formular
//============================================
let slide = 0;

if (document.getElementById("form").length > 0) {
  const form = document.getElementById("form");
  const questions = form.querySelectorAll(".question");
  const btnFor = form.querySelector("#next__wer");
  const btnWann = form.querySelector("#next__wann");
  const btnHg = form.querySelector("#next__hoergeraete-traeger");
  const btnAlter = form.querySelector('[data-name="alter"]');
  const btnPlz = form.querySelector('[data-name="plz"]');
  const btnSubmit = form.querySelector("#next__submit");

  const questionArray = [];

  questions.forEach(q => questionArray.push(q));

  // const bulletNavigation = (el, q) => {
  //   const bulletContainer = document.getElementById(el)
  //   const questions = document.querySelectorAll(q)
  //   const bullets = [];
  //   for (let i = 0; i < questions.length; i++) { 
  //     i === 0 ? bullets.push('<span class="bullet active"></div>') : bullets.push('<span class="bullet"></div>')
  //   }
  //   bullets.forEach(b => { 
  //     bulletContainer.innerHTML += b 
  //   })
  // }

  // const updateBulletNavigation = (i) => { 
  //   const bullets = document.querySelectorAll('.bullet')
  //   bullets[i].classList.add('active')
  //   questions.forEach(q => { 
  //     q.style.left = slide * -100 + '%'
  //   })
  // }

  const getSlide = (slide) => { 
    questionArray.forEach(q => q.classList.remove('active'))
    questionArray[slide].classList.add('active')
  }

  questionArray[slide].classList.add("active");

  const nextQuestion = () => {
    slide++;
    getSlide(slide)
    // updateBulletNavigation(slide)
  };

  const isRadioChecked = name => {
    const radioGroup = form.querySelectorAll("[name=" + name + "]");
    const checkedArray = []
    radioGroup.forEach(r => {      
      if (r.checked) {
        checkedArray.push(true)
      }
    })
    if (checkedArray.length > 0) {
      document.getElementById("alert-" + name).innerHTML = ''
      return true;
    } else { 
      document.getElementById("alert-" + name).innerHTML = '<p>Bitte Auswahl treffen!</p>'
      return false
    }
    
  };

  const checkCharLength = (el, err, val, msg) => {
    const input = document.getElementById(el).value;
    const error = document.getElementById(err);
    if (input.length < val) {
      error.classList.add("alert");
      error.innerHTML = msg;
    } else {
      error.classList.remove("alert");
      error.innerHTML = "";
      nextQuestion();
    }
  };
  const checkStringLength = (el, err, val, msg) => {
    const input = document.getElementById(el).value;
    const error = document.getElementById(err);
    
    if (/\d/.test(input) === false) {
      if (input.length < val) {
        error.classList.add("alert");
        error.innerHTML = msg;
        return false
      } else {
        error.classList.remove("alert");
        error.innerHTML = "";
        return true
      }

    } else { 
      error.classList.add("alert");
      error.innerHTML = msg;

    }    
  };
  const isValidString = (el, val) => {
    const input = document.getElementById(el).value;    
    if (/\d/.test(input) === true || input.length < val) {
      return false
    } else { 
      return true
    }    
  };

  const checkTel = (el, err) => {
    const tel = document.getElementById(el).value;
    const error = document.getElementById(err);
    const check = /^[0-9()+/– -]+$/;
    if (check.test(tel) === false || tel.replace(/[^0-9]/g,"").length < 4) {
      error.classList.add("alert");
      error.innerHTML = "Bitte gültige Telefonnummer eingeben!";
      return false
    } else {
      error.classList.remove("alert");
      error.innerHTML = "";
      return true
    }
  };

 

  const checkAge = (el, error) => {
    const age = document.getElementById(el).value;
    const err = document.getElementById(error);

    if (age < 18 || age > 125) {
      err.innerHTML = "Bitte gültiges Alter angeben";
      err.classList.add("alert");
      return false
    } else {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true
    }
  };


  const checkPlz = (el, error) => {
    const plz = document.getElementById(el).value;
    const err = document.getElementById(error);
    if (plz.length != 5) {
      err.classList.add("alert");
      err.innerHTML = "Bitte gültige Postleitzahl angeben";
      return false
    } else {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true;
    }
  };

  const checkEmail = (el, error) => {
    const email = document.getElementById(el).value;
    const err = document.getElementById(error);
    const reg = /\S+@\S+\.\S+/;
    if (
      reg.test(email) == false
    ) {
      err.classList.add("alert");
      err.innerHTML = "Bitte gültige E-Mail Adresse eingeben";
      return false
    } else {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true
    }
  };


  const isCheckboxChecked = (el, error, msg) => { 
    const checkbox = document.getElementById(el)
    const err = document.getElementById(error);
    if (checkbox.checked) {
      err.classList.remove("alert");
      err.innerHTML = "";
      return true
    } else { 
      err.classList.add("alert");
      err.innerHTML = msg;
      return false
    }
  }

  function getValueFromRadioButton(name) {
    //Get all elements with the name
    var buttons = document.getElementsByName(name);
    for (var i = 0; i < buttons.length; i++) {
      //Check if button is checked
      var button = buttons[i];
      if (button.checked) {
        //Return value
        return button.value;
      }
    }
    //No radio button is selected.
    return null;
  }

//============================================
// Für wen suchen Sie
//============================================
  
 btnFor.addEventListener("click", () => {

   if (isRadioChecked("wer")) { 
     nextQuestion();
     if (document.cookie.indexOf('accepted') > -1) {
      console.log('Tracking Wer');     
     }
   }
});


//============================================
// Wann sind Sie erreichbar
//============================================
  
  
btnWann.addEventListener("click", () => {
    if (isRadioChecked("wann")) { 
      nextQuestion();
      if (document.cookie.indexOf('accepted') > -1) {
        console.log('Tracking Wann');
      }
    }
  });
  

  //============================================
  // Haben Sie Probleme
  //============================================

  // btnProbleme.addEventListener("click", () => {
  
  //   if (isRadioChecked("probleme")) { 
  //     nextQuestion()
  //     console.log('Tracking Probleme');  
  //     let inputValue;
  //   switch (getValueFromRadioButton("probleme")) {
  //     case "Ja":
  //       inputValue = "yes";
  //       break;
  //     case "Nein":
  //       inputValue = "no";
  //       break;
  //   }
  //     console.log(inputValue);
  //   window.digitalData.step_3 = inputValue;
  //   _satellite.track;
  //     ga('send', 'event', 'umfrage', 'click', 'Umfrage Step 3')
  //     _tfa.push({notify: 'event', name: 'Step3', id: 1142431});
  //     obApi('track', 'Step3');     
      
  //   }
  // });

  //============================================
  // Sind Sie Hörgeräteträger
  //============================================

  btnHg.addEventListener("click", () => {
    
    if (isRadioChecked("hoergeraet")) { 
      nextQuestion()
      if (document.cookie.indexOf('accepted') > -1) {
        console.log('Tracking Hörgeräteträger');
      }
    }
  });

  //============================================
  // Alter
  //============================================

  // btnAlter.addEventListener("click", () => {    
  //   if (checkAge("alter", "alertAlter")) { 
  //     nextQuestion()
  //     console.log('Tracking Alter');
  //     window.digitalData.step_5 = form.alter.value;
  //     _satellite.track;
  //     ga('send', 'event', 'umfrage', 'click', 'Umfrage Step 5')
  //   }
  // });

  //============================================
  // PLZ
  //============================================

  btnPlz.addEventListener("click", () => {
    
    if (checkPlz("plz", "alertPlz")) { 
      nextQuestion()
      if (document.cookie.indexOf('accepted') > -1) {
        console.log('Tracking PLZ');
      }

    }    
  });

  //============================================
  // Telefon
  //============================================

  // btnTel.addEventListener("click", () => {    
  //   if (checkTel("tel", "alertTel")) {
  //     nextQuestion()
  //     console.log('Tracking Telefon');
  //     ga('send', 'event', 'umfrage', 'click', 'Umfrage Step 7')
  //    }
  // });

  //============================================
  // Name
  //============================================

  // btnName.addEventListener("click", () => { 
    
  //   if (checkStringLength("name", "alertName", 2, "Bitte Namen angeben!")) { 
  //     nextQuestion()
  //     console.log('Tracking Name');
  //     ga('send', 'event', 'umfrage', 'click', 'Umfrage Step 8')
  //   }
  // }
  // );


  //============================================
  // E-Mail
  //============================================

  btnSubmit.addEventListener("click", e => {
    e.preventDefault();
    const checkValidity = [];

    checkEmail("mail", "alert-mail") ? checkValidity.push(true) : checkValidity.push(false)
    isCheckboxChecked('accept__policy', 'alert-accept', '<p>Bitte Datenschutzbedingungen akzeptieren</p>') ? checkValidity.push(true) : checkValidity.push(false)
    checkStringLength("name", "alert-name", 2, "<p>Bitte Namen angeben!</p>") ? checkValidity.push(true) : checkValidity.push(false)
    checkTel("tel", "alert-tel") ? checkValidity.push(true) : checkValidity.push(false)
  
    if (document.getElementById('checksession')) {
        isCheckboxChecked('checksession', 'alert-accept', '<p>Bitte alle Felder akzeptieren</p>') ?checkValidity.push(true) : checkValidity.push(false)
    }

    if (checkValidity.indexOf(false) > -1) {
      return
    } else { 
      if (document.cookie.indexOf('accepted') > -1) {
        console.log('Tracking Senden');
      }
      form.submit();
    }
  
  });

  //bulletNavigation('bullets', '.question')
  
}