function handleCookieBanner(cookieBanner, type, status, duration, cookie) {
  var cookieBannerHeight = cookieBanner.outerHeight();
  if (!duration) {
    duration = 500;
  }
  if (cookie) {
    cookieBanner.addClass("cookie");
  }
  if (type == "overContent") {
    if (!$("body").hasClass("cookie-banner-overContent")) {
      $("body").addClass("cookie-banner-overContent");
      cookieBanner.data("type", "overContent");
    }
    if (status == "show") {
      cookieBanner.slideToggle(duration);
    } else if (status == "hide") {
      cookieBanner.slideToggle(duration, function() {
        $("body").removeClass("cookie-banner-overContent");
        $("body").removeClass("cookie-banner-active");
      });
    } else {
      console.log("unknown status");
    }
  } else {
    console.log("unknown type");
  }
}

function appendJS() {
  $.ajax({
    url: "/includes/trackingHeader.php",
    cache: true
  }).done(function(data) {
    $("head").append(data);
  });

  if ($("html.index").length > 0) {
    $.ajax({
      url: "/includes/trackingBodyIndex.php",
      cache: true
    }).done(function(data) {
      $("body").prepend(data);
    });
  }

  // if ($("html.danke").length > 0) {
  //   $.ajax({
  //     url: "/includes/trackingHeaderDanke.php",
  //     cache: true
  //   }).done(function(data) {
  //     $("head").append(data);
  //   });
  //   $.ajax({
  //     url: "/includes/trackingBodyDanke.php",
  //     cache: true
  //   }).done(function(data) {
  //     $("body").prepend(data);
  //   });
  // }
}

/**
 * Cookie-Banner / Banner für Informationen
 */
var setCookie = true;

function setStatusCookie(cname, cvalue, sessionOnly) {
  var d = new Date();
  d.setTime(d.getTime() + 3600 * 1000 * 24 * 365);
  var expires = "expires='" + d.toUTCString() + "';";
  if (sessionOnly) {
    expires = "";
  }
  document.cookie = cname + "=" + cvalue + ";" + expires + "path=/";
}

function cookieAlreadySet(cname) {
  var ca = document.cookie.split(";");
  var innerStatus = false;
  for (var i = 0; i < ca.length; i++) {
    var k = ca[i].split("=");
    var key = k[0].trim();
    if (key == cname) {
      innerStatus = true;
    }
  }
  return innerStatus;
}

$(document).on("click", ".cookie-banner-close", function(e) {
  e.preventDefault();
  e.stopPropagation();
  if (
    $(this)
      .closest(".cookie-banner")
      .hasClass("cookie")
  ) {
    setStatusCookie("accepted", 1, false);
    appendJS();
  }
  handleCookieBanner(
    $(this).closest(".cookie-banner"),
    $(this)
      .closest(".cookie-banner")
      .data("type"),
    "hide"
  );
});

$(window).on("load resize orientationchange", function(e) {
  if (e.type == "load") {
    var cookieAcceptedStatus = cookieAlreadySet("accepted");
    if (setCookie && !cookieAlreadySet("accepted")) {
      setCookie = false;
      handleCookieBanner(
        $(".cookie-banner"),
        "overContent",
        "show",
        false,
        true
      );
    }
  }
});

$(document).ready(function($) {
  var cookieAcceptedStatus = cookieAlreadySet("accepted");
  if (cookieAcceptedStatus == true) {
    appendJS();
  } else {
  }
});
