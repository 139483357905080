var accordionItem = $(".accordion-item");

$(".is-active")
  .find(".accordion-content")
  .slideDown();

accordionItem.on("click", function(e) {
  e.preventDefault();

  $(".accordion-content").slideUp();
  //$(this).toggleClass("is-active");
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $(this)
      .find(".accordion-content")
      .slideUp();
  } else {
    accordionItem.removeClass("is-active");
    $(this).addClass("is-active");
    $(this)
      .find(".accordion-content")
      .slideDown();
  }
});

// $(document).ready(function() {
//   "use strict";
//   $(".accordion-item")
//     .stop()
//     .click(function(e) {
//       e.preventDefault();
//       $(this).toggleClass("is-active");
//       $(this)
//         .find(".accordion-content")
//         .toggleClass("active");
//     });
// });
